
import { defineComponent,h  } from "vue"
import { syncedStore, getYjsValue, filterArray } from "@syncedstore/core"
import { WebrtcProvider } from "y-webrtc"
import { WebsocketProvider } from 'y-websocket'
import { IndexeddbPersistence } from "y-indexeddb"
import { uuidv4 } from "lib0/random"
import * as awarenessProtocol from "y-protocols/awareness"
import {Doc} from 'yjs'
import {  PeopleSettings20Filled, PersonSettings20Filled, People20Filled, Person20Filled, QrCode20Filled, 
          Question20Filled, Wifi120Regular, WifiOff20Regular, Checkmark12Filled, Copy16Regular, ArrowReset20Filled, 
          Play20Regular, Delete16Regular} from '@vicons/fluent'
import{Initializer} from "./Initializer"
import { NIcon } from "naive-ui"
import {Md5} from 'ts-md5/dist/md5'
import Cookies from 'js-cookie'


const pathname = document.location.pathname
var id : string

if(pathname.length < 2) {
  id = uuidv4()
  window.history.pushState({ }, '', document.location.origin+'/'+ id)
} else {
  id = pathname.split('#')[0]
}

class Toggle {
    handle = 0
    callFunction
    milliseconds
    constructor(call : any, milliseconds :number) {
      this.callFunction = call
      this.milliseconds = milliseconds
    }
    call() {
      if(this.handle) window.clearTimeout(this.handle)
      this.handle = window.setTimeout(this.callFunction, this.milliseconds)
    }
}

const toggleClipIcon = new Toggle(()=>{
  window.vm.clipIcon = 'copy'
  },666)

interface Indentified {id:string}
interface User extends Indentified { 
  name: string,
  email : string, 
  createdAt: number, 
  icon: string,
  online: boolean,
  estimating: boolean,
  ready: boolean,
  estimate: string
}
interface JokerPoker {
   title : string,
   values : string,
   command : string,
}

const store = syncedStore({
    jp : {} as JokerPoker,
    users : [] as User[]
  })


const ydoc = getYjsValue(store) as Doc

const wsProvider = new WebsocketProvider('wss://private-mango-chili.glitch.me', id, ydoc)
// const wsProvider = new WebsocketProvider('wss://demos.yjs.dev', id, ydoc)

wsProvider.on('status', (event : any) => {
  window.vm.wsConnected = 'connected' == event.status
})

const awareness = wsProvider.awareness

new WebrtcProvider(id, ydoc, {
                                                // see https://github.com/yjs/y-webrtc#user-content-api
                                                signaling: ['wss://signaling.yjs.dev', 'wss://y-webrtc-signaling-eu.herokuapp.com', 'wss://y-webrtc-signaling-us.herokuapp.com'],
                                                password: id.split("").reverse().join("!"),
                                                awareness: awareness,
                                                maxConns: 27 +  Math.floor(Math.random() * 15),
                                                filterBcConns: true,
                                                peerOpts: {}
                                              })
                                              

new IndexeddbPersistence(id, ydoc)
new Initializer(
        ydoc,
        wsProvider, ()=>{ 
          window.vm.initialised = true
        },
        111)

var myID = localStorage.getItem(id) as string
if(!myID) {
  myID = uuidv4()
  localStorage.setItem(id,myID)
}
var myName = Cookies.get('myName') || ''
var myEmail = Cookies.get('myEmail') || ''

awareness.setLocalState({id: myID} as Indentified)

function renderIcon (icon: any) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const defaultPage = 'team/estimate'
export default defineComponent({
  components : {
    PeopleSettings20Filled,
    PersonSettings20Filled,
    People20Filled,
    Person20Filled,
    QrCode20Filled, 
    Question20Filled, 
    Wifi120Regular, 
    WifiOff20Regular,
    Checkmark12Filled,
    Copy16Regular,
    ArrowReset20Filled,
    Play20Regular,
    Delete16Regular
    },

  data() {
    return {
      myID : myID,
      shared: store,
      collapsed : this.isSmall(window.innerWidth),
      initialised : false,
      currentPageId : defaultPage,
      defaultValues : "☕\n1\n2\n3\n5\n8\n13\n20\n40\n?",
      loadingUser : {name :'Loading',email :'', createdAt : new Date().getTime(), icon : 'tail-spin.svg', id : 'loading', online : false, estimating:false, estimate : '', ready :false },
      wsConnected : false,
      sessionURL : window.location.href.split('#')[0],
      clipIcon : 'copy',
      navMenuOptions : [
         {
            label: 'Team Estimate',
            key: defaultPage,
            icon: renderIcon(People20Filled)
         },
         {
            label: 'My Estimate',
            key: '~/estimate',
            icon: renderIcon(Person20Filled)
         },
         {
            key: 'divider-1',
            type: 'divider',
          },
         {
            label: 'Team Settings',
            key: 'team/settings',
            icon: renderIcon(PeopleSettings20Filled)
          },
          {
            label: 'My Settings',
            key: '~/settings',
            icon: renderIcon(PersonSettings20Filled)
          },
          {
            key: 'divider-2',
            type: 'divider',
          },
          {
            label: 'QR',
            key: 'qr',
            icon: renderIcon(QrCode20Filled)
          },
          {
            label: 'About',
            key: 'about',
            icon: renderIcon(Question20Filled)
          },
      ]
    }
  },

  created(){
    this.onHashChange()
    window.addEventListener("hashchange", this.onHashChange)
    awareness.on("change", (changes: any) => {
      this.syncAwareness()
    })
  },

  computed: {

    currentPageTitle() : string {
      switch(this.currentPageId) {
        case '~/settings'     : return 'My settings'
        case 'team/settings'  : return 'Team settings'
        default : return ''
      }
    },

    ready() : boolean {
      return  !this.currentPageId.endsWith('settings') &&
              this.myself.online && this.myself.estimating && this.myself.estimate == '' && this.myself.name != '' 
    },
    teamReady() : boolean {
      return this.mateCount > 0 && this.estimatingUsers.filter(u=> !u.ready).length == 0
    },

    insetStyle() : string {
      return  this.isSmallScreen ? "padding: 0px; background-color: white;" : "padding: 24px; background-color: #fafafc;"
    },
    
    title() : string {return this.shared.jp.title ||'Joker Poker'},

    isSmallScreen() : boolean {
      return this.isSmall(this.$windowWidth) 
    },

    values(): string[] {
      return (this.shared.jp.values || this.defaultValues).trim().split("\n").map(v => v.trim())
    },
    valuesWithoutQuestionmark(): string[] {
      return this.values.filter(v=> v != '?')
    },
    pageIDs() : string[] {
      return this.navMenuOptions.map(o => o.key)
    },
    myself() : User {
      if(!this.initialised) return this.loadingUser
      var users = this.shared.users
      var me = users.find(u => u.id == myID)
      if(me) return me
      me = {name :'',email :'', createdAt : new Date().getTime(), icon : '', id : myID, online : true , estimating:true, estimate : '', ready : false}
      users.push(me)
      // we have to search again to get the registered object. || me just keeps the compiler happy 
      return users.find(u => u.id == myID) || me
    },
    namedUsers(): User[] {
      return this.shared.users.filter(u => u.name)
    },
    estimatingUsers(): User[] {
      return this.namedUsers.filter(u => u.online && u.estimating )
    },
    mateCount(): number {
      return Math.max(0, this.estimatingUsers.length - (this.myself.estimating?1:0))
    },
    estimates(): string[] {
      return this.estimatingUsers.map(u => u.estimate)
    },
    estimateCount() : number {
      return this.estimatingUsers.filter(u=>u.estimate).length
    },
    estimateProgress(): number {
      return 100 * this.estimateCount / this.estimatingUsers.length;
    },
    estimateDone() : boolean {
      return 100 == this.estimateProgress;
    },
    estimateStarted() : boolean {
      return this.estimateProgress > 0;
    },
    estimateLow() : string | undefined {
      return this.valuesWithoutQuestionmark.find(v=> this.estimates.includes(v))
    },
    estimateHeigh() : string | undefined {
      return this.valuesWithoutQuestionmark.slice().reverse().find(v=> this.estimates.includes(v))
    },
  },

  watch : {
      "shared.jp.title" : function(val) {
        document.title = val
      },
      "shared.jp.command" : function(command) {
        if(command == 'start' && this.ready) this.navigate('~/estimate')
        window.setTimeout(()=>{this.shared.jp.command = ''},444)
      },
      "myself" : function(val) {
          this.syncAwareness()
      },
      "myself.name" : function(val) {
        this.syncIcon()
        Cookies.set('myName', val, { expires: 777})
      },
      "myself.email" : function(val) {
        this.syncIcon()
        Cookies.set('myEmail', val, { expires: 777 })
      },
      estimateCount(val) {
        if(val == 0 && this.myself.estimating && !this.currentPageId.endsWith('settings')) this.navigate('~/estimate') 
      },
      estimateDone(val) {
        if(val && !this.currentPageId.endsWith('settings')) this.navigate('team/estimate') 
      },
      initialised(val) {
        if(val) {
          if(!this.myself.name) {
            this.myself.name = myName ||''
            this.myself.email = myEmail ||''
          }
          this.autoNavigate()
        }
        document.title = this.title
      },
      isSmallScreen(val) {
        this.collapsed = val
      },
      ready(val) {
        this.myself.ready = val
      },
  },

  methods: {

  deleteMate(id:string) {
      filterArray(this.shared.users, (u) => u.id != id)
  },  

  isSmall(width :number):boolean {
    return width < 640
  },
  
  copyToClipBoard(text: string) {
		var copyText = (document.getElementById('clipTmp') || document.body) as HTMLInputElement
		copyText.value= text
		copyText.select()
		copyText.setSelectionRange(0, 99999); /* For mobile devices */
		navigator.clipboard.writeText(copyText.value)
    this.clipIcon='success'
    toggleClipIcon.call()
  },
  
  onCollapsed(collapsed : boolean) {
     this.collapsed = collapsed
   },
  syncIcon() {
       const hash = Md5.hashStr((this.myself.email || this.myself.name).trim().toLowerCase())
       this.myself.icon = 'http://www.gravatar.com/avatar/'+hash+'?d=monsterid'
    },
    
    getType(estimate : string) : string {
      if(!this.estimateDone) return ''
      if(estimate == '?') return ''
      if(this.estimateLow == this.estimateHeigh) return 'success'
      if(this.estimateLow == estimate) return 'error'
      if(this.estimateHeigh == estimate) return 'warning'
      return ''
    },

    reset() {
      this.shared.users.forEach(u=>u.estimate = '')
    },
    
    reveal() {
      this.shared.users.forEach(u=>u.estimate = u.estimate || '?')
    },

    autoNavigate () {
      if      (!this.myself.name)   this.navigate('~/settings')
      else if (!this.mateCount)     this.navigate('qr')
      else if (this.myself.estimating && this.estimateStarted && 
              !this.estimateDone)   this.navigate('~/estimate')
      else                          this.navigate('team/estimate')
    },
    navigate (key : string) {
        
        if('about' == key ){
          window.open('https://codeberg.org/culmat/joker-poker/src/branch/main/README.md', 'aboutJokerPoker')
          window.setTimeout(()=>{
            this.onHashChange()
          },5)
        } else {
          window.location.hash = key
        }
        if(this.isSmallScreen) this.collapsed = true
    },

    onHashChange() {
      const hash = window.location.hash.substring(1)
      if (this.pageIDs.includes(hash)) {
        this.currentPageId = hash
      } else {
        window.location.hash = ""
        this.currentPageId = defaultPage
      }
    },

    syncAwareness(){
      const awarenesStates = Array.from(awareness.getStates().values()) as Indentified[]
      this.shared.users.forEach(u=>{
        u.online = awarenesStates.find(i => i.id == u.id) != undefined
      })
    },
  },
})


